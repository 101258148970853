import React, { Fragment, useState, useEffect } from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import 'moment/locale/ru';
import { Times } from '../Times/Times';

import sadsmile from '../../../img/sadsmile.png';
import './Days.css';

export const Days = ({
    choseDateEvent, 
    choseDateStatus, 
    choseStationEvent, 
    dataTimes, 
    stationAttributes, 
    createOrder, 
    order
}) => {
    const [timeList,  setTimeList] = useState([]);
    const [selectedDate,  setSelectedDate] = useState('');
    //const [counter,  setCounter] = useState(0);
    
    const moment = extendMoment(Moment);
    const start = new Date();
    start.setDate(start.getDate() + 2);

    const end = new Date();
    end.setDate(new Date().getDate() + 16);

    const range = moment.range(start, end);
    let dateList = Array.from(range.by('days'))

    const arrTimes = dataTimes.data;
    let closeTimeCount = 0;

    useEffect(() => {
        if (choseDateStatus) {
            setTimeList([...arrTimes?.filter(item => {
                const dateTime = item.attributes.datetime;
                return dateTime.includes(selectedDate); 
                })]
            )
        } 
    }, [choseDateStatus]);

    function choseDate(item){
        choseDateEvent();
        setSelectedDate(item.locale('ru').format('YYYY-MM-DD'));
    }

    return <Fragment>
        <div className="text-center pt-2">
            <h5>{stationAttributes.station_name}</h5>
            <div className="address_info d-flex flex-row justify-content-center">
                <div className="icon_address"></div>
                <span className="station_address">{stationAttributes.address}</span>
            </div>
            <div className="phone_info d-flex flex-row justify-content-center">
                <div className="icon_phone"></div>
                <span className="station_phone">{stationAttributes.phone}</span>
            </div>
            <hr className="linear"/>
        </div>
        <div className='cardContent'>
            <div className="row d-flex justify-content-center">
                {   
                    choseDateStatus ? 
                    timeList.length ? 
                    <Fragment>
                        <p className="date_selection text-center">Выберите доступное время:</p>
                        {
                            timeList.map((time) => {
                                if(!time.attributes.close_time){
                                    closeTimeCount++;
                                }
                            })
                        }
                        {
                            closeTimeCount > 0 ? 
                            timeList.map((time) => {
                                if(!time.attributes.close_time){
                                    return <Times 
                                        time={time} 
                                        key={time.id}
                                        createOrder={createOrder}
                                        order={order}
                                        stationAttributes={stationAttributes}
                                    /> 
                                }
                            }) :  
                                
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                <img src={sadsmile} alt="sad" width="50px" className="m-4"/>
                                <p className="text-center">К сожалению, на выбранный день нет доступного времени для записи. Попробуйте выбрать другой день!</p>      
                            </div>
                        }
   
                            <div className="d-flex justify-content-center">
                                <button className="custom-btn btn-prev mt-4" onClick={()=>choseDateEvent()}><span>Назад</span></button>
                            </div>
                    </Fragment> :  
                        <div className=" d-flex flex-column justify-content-center align-items-center"> 
                            <img src={sadsmile} alt="sad" width="50px" className="m-4"/>
                            <p className="text-center">К сожалению, на выбранный день нет доступного времени для записи. Попробуйте выбрать другой день!</p>    
                            <div className="d-flex justify-content-center">
                                <button className="custom-btn btn-prev mt-4" onClick={()=>choseDateEvent()}><span>Назад</span></button>
                            </div>
                        </div> :
                        
                        <Fragment>
                            <p className="date_selection text-center">Выберите дату:</p>
                            {
                                dateList.map((item) => {
                                    return <div key={item} className="card col-md-2 col-sm-3 card_day text-center" onClick={() => choseDate(item)}>
                                            <div className="card-body">
                                                <h5 className="card-title">{item.locale('ru').format('DD MMMM')}</h5>
                                                <h5 className="days-of-weak fst-italic">{item.locale('ru').format('dddd')}</h5>
                                            </div>
                                    </div>
                                })
                            }
                            <div className="d-flex justify-content-center">
                                <button className="custom-btn btn-prev mt-4" onClick={()=>choseStationEvent()}><span>Назад</span></button>
                            </div>
                        </Fragment>
                    }
                </div>
        </div>
    </Fragment>
}
