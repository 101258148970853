import {CHOSE_DATE} from '../types';

const initialState = {
    isChosed: false
};

export const switchingDate = (state=initialState, action) => {
    switch (action.type) {
        case CHOSE_DATE:
            return{
                ...state,
                isChosed: !state.isChosed
            };
        // case FETCH_DATA_REJECTED:
        //     return{
        //         ...state,
        //         isLoadingData: false,
        //         isFetchedData: false,
        //         error: action.payload.message
        //     };
        default:
            return state;
    }
};