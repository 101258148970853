import {Component} from 'react';
import {connect} from 'react-redux';
import {choseStation, choseDate, getFetchDataStation, getFetchDataTimes, createOrder} from './store/actions/actions';
import {Header} from './components/Header/Header';
import {Footer} from './components/Footer/Footer';
import {Dashboard} from './components/Dashboard/Dashboard';

import './App.css';


class App extends Component{

  componentDidMount(){
    this.props.fetchDataStation();   
  }

  choseStation(stationAttributes){
    if(stationAttributes){
      this.props.fetchDataTimes(stationAttributes.station_id); 
      this.props.choseStation(stationAttributes) 
    } else {
      this.props.choseStation()
    }
  }

  prev(stationAttributes){
    this.props.fetchDataTimes(stationAttributes.station_id); 
  }

  choseDate(){
    this.props.choseDate();  
  }

  createOrder(info, timeID){
    this.props.createOrder(info, timeID)
  }


  render(){
    const stations = this.props.data;
    const {isFetchedData, isLoadingData, error} = this.props.fetchStatus;
    const {isChosed} = this.props.choseStationStatus;
    const choseDateStatus= this.props.choseDateStatus.isChosed;
    const dataTimes = this.props.dataTimes;
    const stationAttributes = this.props.stationAttributes;
    const order = this.props.order;

    return (
      <div className="container-fluid my-wrapper">
          <Header />
          <Dashboard 
            stations={stations}
            stationAttributes={stationAttributes}
            isFetchedData={isFetchedData}
            isLoadingData={isLoadingData}
            error={error}
            choseStatus={isChosed}
            choseDateStatus={choseDateStatus}
            choseStationEvent={this.choseStation.bind(this)}
            choseDateEvent={this.choseDate.bind(this)}
            createOrder={this.createOrder.bind(this)}
            dataTimes={dataTimes}
            order={order}
          />
          <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  data: state.station.data,
  fetchStatus: state.station,
  choseStationStatus: state.switchingStation,
  choseDateStatus: state.switchingDate,
  dataTimes: state.times,
  stationAttributes: state.switchingStation,
  order: state.order
});

const mapDispatchToProps = dispatch => {
  return{
    fetchDataStation: () => dispatch(getFetchDataStation()),
    fetchDataTimes: stationID => dispatch(getFetchDataTimes(stationID)),
    choseStation: stationAttributes => dispatch(choseStation(stationAttributes)),
    choseDate: () => dispatch(choseDate()),
    createOrder: (info, timeID) => dispatch(createOrder(info, timeID))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
