import React from 'react';

import './StationCard.css';

export const StationCard = ({station, keyStation, choseStationEvent}) => (
    <div className="col-md-4 col-sm-6"  onClick={() => choseStationEvent(station.attributes)}>
        <div key={keyStation} className="card p-0 mb-3 station_card">
            <h6 className="card-header text-center">{station.attributes.station_name}</h6>
            <div className="card-body">
                <div className="address_block">
                    <div className="icon_address"></div>
                    <p className="card-title fw-bold ">{station.attributes.address}</p>
                </div>
                <div className="phone_block">
                    <div className="icon_phone"></div>
                    <p className="fst-italic">{station.attributes.phone}</p>
                </div>
                <div className="categories_block">
                    <div className="icon_categories"></div>
                    <p>Категории: <span className="cat_info">{station.attributes.categories}</span></p>
                </div>
            </div>

        </div>
    </div>
)


