import React from 'react';
import logo from '../../img/logo.png';

import './Header.css';

export const Header = () => (
    <header className="p-3 d-flex flex-row justify-content-center header">
        <img src={logo} alt="Logo" width='115px' height='88px' />
        <h2 className="text-center text-dark d-flex align-items-center titleAPP">My ZapiS</h2>
    </header>
);