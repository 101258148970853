import { combineReducers } from 'redux';
import { station } from './station';
import { switchingStation } from './switchingStation';
import { switchingDate } from './switchingDate';
import { times } from './times';
import { order } from './order';


export const rootReducer = combineReducers({
    station, 
    switchingStation, 
    switchingDate, 
    times,
    order
});