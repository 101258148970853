import React, {Fragment} from 'react';
import {Loading} from '../Loading/Loading';
import {StationCard} from './StationCard/StationCard';
import {Days} from './Days/Days'

import sadsmile from '../../img/sadsmile.png';
import './Dashboard.css';

export const Dashboard = ({
    stations, 
    stationAttributes,
    isFetchedData, 
    isLoadingData, 
    error,
    choseStatus, 
    choseDateStatus,
    choseStationEvent,
    choseDateEvent,
    createOrder,
    dataTimes,
    order
}) => {

    return <Fragment>
        <h3 className="text_info_system p-2">
            система онлайн записи на техосмотр ОАО«Витебскоблавтотранс»
        </h3>
        <div className="zapis_zone">
            {
                isLoadingData ? 
                <Loading /> : 
                !isFetchedData ? 
                    <div className="d-flex flex-column align-items-center m-5">
                        <img src={sadsmile} alt="sad" width="50px"/>
                        <p className="m-5">{error}</p>
                        <button 
                            className="custom-btn btn-prev mt-4" 
                            onClick={() => {window.location.reload(); return false;}}>
                            <span>Назад</span>
                        </button>
                    </div> :
                choseStatus ? 
                <Fragment>
                    <Days 
                        choseDateEvent={choseDateEvent}
                        choseDateStatus={choseDateStatus}
                        choseStationEvent={choseStationEvent}
                        dataTimes={dataTimes}
                        stationAttributes={stationAttributes}
                        createOrder={createOrder}
                        order={order}
                    /> 
                </Fragment>: 

                <Fragment>
                    <p className="station_selection text-center mt-3">Выбор диагностической станции</p>
                    <div className="row d-flex stations ">
                        {
                            stations.map(station => (
                                <StationCard 
                                    station={station}
                                    key={station.id}
                                    choseStationEvent={choseStationEvent}
                                />
                            ))
                        }
                    </div>  
                </Fragment>
            }
        </div>
    </Fragment>
};