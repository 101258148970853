import React from 'react';

import './Footer.css';

export const Footer = () => (
    <footer className="p-3 d-flex flex-row justify-content-center header">
        <div className="copyright justify-content-center">
            <span className="text-decoration-none text-white">&copy; ОАО "Витебскоблавтотранс"</span>
        </div>
    </footer>
);