import {NEW_ORDER, CREATE_ORDER_REJECT} from '../types';

export const order = (state=[], action) => {
    //console.log(action.payload.message)
    switch (action.type) {
        case NEW_ORDER:
            return{
                ...state,
                ...action.data,
            };
        case CREATE_ORDER_REJECT:
            return{
                ...state,
                error: action.payload.message
            }

        default:
            return state;
    }
};