import {FETCH_DATA_FULFILLED, FETCH_DATA_TIMES, FETCH_DATA_REJECTED, CHOSE_STATION, CHOSE_DATE, NEW_ORDER, CREATE_ORDER_REJECT} from '../types';

export function getFetchDataStation() {
    return (dispatch)=> {
        const URL = 'https://diag.voat.by/ds/station/all';
        
         fetch(URL)
            .then(res => res.ok ? res : Promise.reject(res))
            .then(res => res.json())
            .then(data =>  dispatch(getDataStation(data)))
            .catch(() => dispatch(getDataFetchError()));
    };
}

export function getFetchDataTimes(stationID) {

    return (dispatch)=> {
        const URL = `https://diag.voat.by/ds/station/${stationID}/times/available`;
        
         fetch(URL)
            .then(res => res.ok ? res : Promise.reject(res))
            .then(res => res.json())
            .then(data =>  dispatch(getDataTimes(data)))
            .catch(() => dispatch(getDataFetchError()));
    };
}

export function createOrder(info, timeID){

    return(dispatch) => {
        const URL = 'https://diag.voat.by/ds/order/booking';
        fetch(URL, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                "fio": info.fio,
                "tel": info.phone,
                "time_id": timeID,
                "brand": info.marka,
                "model": info.gosNumber,
                "note": info.note
            })
        }).then(res => res.ok ? res : Promise.reject(res))
        .then(res => res.json())
        .then(data => dispatch({
            type: NEW_ORDER,
            data
        }))
        .catch(() => dispatch(createOrderError()));
    }
}


export function getDataStation(data){
    return{
        type: FETCH_DATA_FULFILLED,
        data
    }
}

export function getDataTimes(data){
    return{
        type: FETCH_DATA_TIMES,
        data
    }
}

export function getDataFetchError(){
    return{
        type: FETCH_DATA_REJECTED,
        payload: new Error('К сожалению, на данной станции нет доступного времени для электронной записи. Доступное время уточняйте по телефону или выберите другую станцию для записи.')
    }
}

export function createOrderError(){
    return{
        type: CREATE_ORDER_REJECT,
        payload: new Error('К сожалению, запись на выбранное время больше не доступна. Попробуйте выбрать другое время или дату.')
    }
}

export function choseStation(stationAttributes){

    return{
        type: CHOSE_STATION,
        stationAttributes
    }
}

export function choseDate(){

    return{
        type: CHOSE_DATE,
    }
}
