import React,{ useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import './Times.css';

export const Times = ({time, keyTime, createOrder, order, stationAttributes}) => {
    const date = new Date(time.attributes.datetime);
    const isolatedTime = date.getHours()+':'+(date.getMinutes()<10 ? '0' : '') + date.getMinutes();
    const isolatedDate = date.toLocaleDateString();

    const [show, setShow] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);

    const handleClose = () => setShow(false);
    const handleShow = (breakpoint) => {
        setFullscreen(breakpoint);
        setShow(true);
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        if(fio !== '' && marka !== '' && gosNumber !== '' && phone !== ''){
          createOrder({fio,marka,gosNumber,phone,note}, time.id);
          setStatusSubmit(true);
        } else {
          setControlOrder(false);
        }
    }

    const [fio, setFio] = useState('');
    const [marka, setMarka] = useState('');
    const [gosNumber, setGosNumber] = useState('');
    const [phone, setPhone] = useState('');
    const [note, setNote] = useState('');

    const [statusSubmit, setStatusSubmit] = useState(false);
    const [controlOrder, setControlOrder] = useState(true);

    return <div className="col-md-2  col-sm-3 col-6" >
            <div key={keyTime} className="card card_time p-0 mb-3 text-center" onClick={()=>handleShow(true)}>
                <div className="card-body">
                    <h5 className="card-text ">{isolatedTime}</h5>
                </div>
            </div>
            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
              <Modal.Header>
                <Modal.Title className="text-center">Форма записи онлайн</Modal.Title>
              </Modal.Header>
              
                {
                  statusSubmit ?
                  order.data ?
                  <Modal.Body className="succes">
                  <Form className="d-flex justify-content-center">
                    <div className="form_success">
                      <h4>Вы были записаны на услугу "Техосмотр"!</h4>
                      <Form.Group className="mb-3" controlId="Zapis.ControlInfo1">
                        <Form.Label>Дата: {isolatedDate}</Form.Label><br />
                        <Form.Label>Время: {isolatedTime}</Form.Label>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="Zapis.ControlInfo1">
                        <Form.Label>Станция: {stationAttributes.station_name}</Form.Label><br />
                        <Form.Label>Адрес: {stationAttributes.address}</Form.Label><br />
                        <Form.Label>Телефон станции для справок: {stationAttributes.phone}</Form.Label><br />
                        <Form.Label>ФИО: {order.data[0].attributes.fio}</Form.Label><br />
                        <Form.Label>Марка автомобиля: {order.data[0].attributes.brand}</Form.Label><br />
                        <Form.Label>Гос. номер: {order.data[0].attributes.model}</Form.Label><br />
                        <Form.Label>Телефон: {order.data[0].attributes.tel}</Form.Label><br />
                        <Form.Label>Комментарий к записи: {order.data[0].attributes.note}</Form.Label><br />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="Zapis.ControlButton1">
                        <Button variant="primary" type ="submit" >
                            Вернуться на главную страницу
                        </Button>
                      </Form.Group>
                    </div>
                  </Form>
                  </Modal.Body> :

                  <Modal.Body>           
                  <Form>
                    <h3>{order.error} :(</h3>
                    <Form.Group className="mb-3" controlId="Zapis.ControlButton2">
                      <Button variant="primary" type ="submit" >
                          Вернуться на главную страницу
                      </Button>
                    </Form.Group>
                  </Form> 
                  </Modal.Body>:

                  <Modal.Body className="form_zapis_body">
                  <Form onSubmit={handleSubmit} className="form_zapis">
                    <Form.Group className="mb-3" controlId="Zapis.ControlInput1">
                      <Form.Label>Станция: <span className="fw-bold">{stationAttributes.station_name}</span></Form.Label><br />
                      <Form.Label>Адрес: <span className="fw-bold">{stationAttributes.address}</span></Form.Label><br />
                      <Form.Label>Дата: <span className="fw-bold text-danger">{isolatedDate}</span></Form.Label><br />
                      <Form.Label>Время: <span className="fw-bold text-danger">{isolatedTime}</span></Form.Label>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Zapis.ControlInput2">
                      <Form.Label>Фамилия Имя Отчество<span className="text-danger"> *</span>:</Form.Label>
                      <Form.Control
                        type="text"
                        value={fio}
                        onChange={e => {
                          setFio(e.target.value); 
                          setControlOrder(true);
                        }}
                        placeholder="например, Иванов Иван Иванович"
                        autoFocus
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Zapis.ControlInput3">
                      <Form.Label>Марка автомобиля <span className="text-danger"> *</span>:</Form.Label>
                      <Form.Control
                        type="text"
                        value={marka}
                        onChange={e => {
                          setMarka(e.target.value);
                          setControlOrder(true);
                        }}
                        placeholder="например, Фольксваген пассат В6"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Zapis.ControlInput4">
                      <Form.Label>Гос номер <span className="text-danger"> *</span>:</Form.Label>
                      <Form.Control
                        type="text"
                        value={gosNumber}
                        onChange={e => {
                          setGosNumber(e.target.value);
                          setControlOrder(true);
                        }}
                        placeholder="например, 1234 АВ-2"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Zapis.ControlInput5">
                      <Form.Label>Телефон <span className="text-danger"> *</span>:</Form.Label>
                      <Form.Control
                        type="text"
                        value={phone}
                        onChange={e => {
                          setPhone(e.target.value);
                          setControlOrder(true);
                        }}
                        placeholder="+375ХХХХХХХХХ"
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="Zapis.ControlTextarea1"
                    >
                    <Form.Label>Комментарий:</Form.Label>
                    <Form.Control 
                      as="textarea" 
                      value={note}
                      onChange={e => {
                        setNote(e.target.value);
                        setControlOrder(true);
                      }}
                      rows={4} 
                    />
                  </Form.Group>
                  <Form.Label className={controlOrder ? "text-black" : "text-danger display-6"}>* - поля, обязательные для заполнения</Form.Label>
                  <Form.Group className="mb-3" controlId="Zapis.ControlButton3">
                      <Button variant="secondary" className="m-1" onClick={handleClose}>
                          Отмена
                      </Button>
                      <Button variant="primary" className="m-2" type ="submit" >
                          Записаться
                      </Button>
                  </Form.Group>
                  </Form>
                  </Modal.Body>
                }
          

              <Modal.Footer>
                 ОАО "Витебскоблавтотранс"
              </Modal.Footer>
            </Modal>
    </div>
  }

