import {FETCH_DATA_TIMES} from '../types';

export const times = (state=[], action) => {
    
    switch (action.type) {
        case FETCH_DATA_TIMES:
            return{
                ...state,
                ...action.data,
            };
        // case FETCH_DATA_REJECTED:
        //     return{
        //         ...state,
        //         isLoadingData: false,
        //         isFetchedData: false,
        //         error: action.payload.message
        //     };
        default:
            return state;
    }
};